import { logError } from 'App/services/coralogixService';
import {LOCAL_STORAGE_IDS} from 'Shared/constants';
import {OPAOptimizelyDecision} from 'Shared/models/swagger/opa';
import {getLocalStorage, setLocalStorage} from 'Shared/services/localStorageService';
import { OptimizelyCache, OptimizelyDecisionCache } from 'Shared/services/localStorageTypes';
import {getOptimizelyDecisions} from 'Shared/services/opa/api/opaApi';
import {OPA_FLAG} from 'Shared/services/opa/constants';

const OPTIMIZELY_CACHE_TTL = 60 * 60 * 1000; // 1 hour

/**
 * Returns the split test cache
 * @returns {OptimizelyCache | null}
 */
const getSplitTestCache = (): OptimizelyCache | null => {
  return getLocalStorage(LOCAL_STORAGE_IDS.SPLIT_TEST_CACHE, true);
};

/**
 * Returns back the decisions for the given flag keys
 * @param flagKeys
 * @returns
 */
const getSplitTestConfigs = (flagKeys: OPA_FLAG[]): OPAOptimizelyDecision[] => {
  return getOptimizelyCache(flagKeys);
};

const getSplitTestConfigsAsync = (flagKeys: OPA_FLAG[]): Promise<OPAOptimizelyDecision[]> => {
  return new Promise((resolve, reject) => {
    try {
      getOptimizelyCache(flagKeys, (error) => {
        if (error) {
          reject(error);
        } else {
          resolve(getOptimizelyCache(flagKeys));
        }
      });
    } catch (error) {
      logError('getSplitTestConfigsAsync', error);
      reject(error);
    }
  });
};

/**
 * Sets the optimizely decision cache
 * @param optimizelyCache
 */
const setOptimizelyCache = (optimizelyCache: OptimizelyDecisionCache[]) => {
  setLocalStorage(LOCAL_STORAGE_IDS.SPLIT_TEST_CACHE, {
    lastUpdated: Date.now(),
    store: optimizelyCache
  }, true);
};

/**
 * Gets the cached optimizely decision
 * @param flagKey
 * @returns
 */
const getCachedOptimizelyDecision = (flagKey: OPA_FLAG): OptimizelyDecisionCache | undefined => {
  const optimizelyCache = getLocalStorage(LOCAL_STORAGE_IDS.SPLIT_TEST_CACHE, true);

  if (!optimizelyCache?.store) {
    return;
  }

  return optimizelyCache.store.find((decisionCache: OptimizelyDecisionCache) => decisionCache.flagKey === flagKey);
};

/**
 * Checks the local cache for the shared optimizely keys and returns the ones it has.
 * For the keys that were not found or were expired, it will request an update from OPA.
 * @param flagKeys
 * @returns
 */
const getOptimizelyCache = (flagKeys: OPA_FLAG[], callbackFunction?: (error?: any) => void): OPAOptimizelyDecision[] => {
  const optimizelyCache = getSplitTestCache();

  // If there isn't a cache store or the cache
  // is expired, return an empty array and update
  // the local cache.
  if (!optimizelyCache?.store || cacheRequiresUpdate(optimizelyCache.lastUpdated)) {
    updateOptimizelyCache(flagKeys, [], callbackFunction);
    return [];
  }

  const matches: OPAOptimizelyDecision[] = [];

  // Go through the cache store and return the matched caches that are still valid.
  // For those that are expired, update the local cache.
  optimizelyCache.store.forEach((decisionCache: OptimizelyDecisionCache) => {
    if (flagKeys.includes(decisionCache.flagKey as OPA_FLAG)) {
      if (!cacheRequiresUpdate(decisionCache.lastUpdated) && decisionCache.decision) {
        matches.push(decisionCache.decision);
      }
    }
  });

  // Update the local cache
  if (flagKeys.length !== matches.length) {
    updateOptimizelyCache(flagKeys, optimizelyCache.store, callbackFunction);
  } else {
    callbackFunction?.();
  }

  return matches;
};

/**
 * Returns true if the cache requires an update
 * @param lastUpdated
 * @returns
 */
const cacheRequiresUpdate = (lastUpdated: number): boolean => {
  const maxExpiredTTL = Date.now() - OPTIMIZELY_CACHE_TTL;
  return lastUpdated < maxExpiredTTL;
};

/**
 * Accepts a list of flag keys that might need to be updated and the latest decision cache.
 * In order to optimize the calls to OPA, it then collects all the decision caches that are currently
 * stored locally and checks for missing or expired decision caches. It then updates the local decision cache.
 * @param flagKeys
 * @param store
 */
const updateOptimizelyCache = (flagKeys: OPA_FLAG[], store: OptimizelyDecisionCache[], callbackFunction?: (error?: any) => void) => {
  // Get a list of decisions that are needed but are missing from the cache
  const missingCacheKeys = flagKeys.filter((key: OPA_FLAG) => !store.some((decisionCache: OptimizelyDecisionCache) => decisionCache.flagKey === key));

  // Get a list of expired caches that should be updated
  const expiredCacheKeys = store.filter((decisionCache: OptimizelyDecisionCache) => cacheRequiresUpdate(decisionCache.lastUpdated));

  // Don't call OPA if there aren't missing decisions and expired decision caches
  if (missingCacheKeys.length === 0 && expiredCacheKeys.length === 0) {
    callbackFunction?.();
    return;
  }

  // Get all the stored flag keys so that we can update them
  const allStoredFlagKeys = store.map((decisionCache: OptimizelyDecisionCache) => decisionCache.flagKey);

  // Combine them with the missing decision caches
  const flagKeysToUpdate  = Array.from(new Set([...missingCacheKeys, ...allStoredFlagKeys]));

  // Request OPA to update the decision caches
  getOptimizelyDecisions(flagKeysToUpdate).then((optimizelyDecisions: OPAOptimizelyDecision[]) => {
    // Get the latest decision cache in case there are multiple calls to OPA
    // and the memory is no longer valid.
    const optimizelyCache = getSplitTestCache();
    const store: OptimizelyDecisionCache[] = optimizelyCache?.store || [];

    // Cache the update timestamp
    const updateTimestamp                  = Date.now();

    // Update all the decisions in the cache
    optimizelyDecisions.forEach((decision: OPAOptimizelyDecision) => {
      for (let x = 0; x < store.length; x++) {
        // If we find a match then update the decision cache
        if (store[x].flagKey === decision.flagKey) {
          store[x].decision    = decision;
          store[x].lastUpdated = updateTimestamp;
          return;
        }
      }

      // Store all the new decisions in the cache
      store.push({
        flagKey: decision.flagKey as OPA_FLAG,
        decision: decision,
        lastUpdated: updateTimestamp
      });
    });

    // Go through all the decisions and if any were missing from the API
    // then add them to the cache with an empty decision to prevent future calls.
    flagKeys.forEach((key: OPA_FLAG) => {
      for (let x = 0; x < store.length; x++) {
        if (store[x].flagKey === key) {
          store[x].lastUpdated = updateTimestamp;
          return;
        }
      }

      store.push({
        flagKey: key,
        decision: null,
        lastUpdated: updateTimestamp
      });
    });

    // Store the cache locally
    setOptimizelyCache(store);

    callbackFunction?.();
  }).catch((error) => {
    logError('getOptimizelyDecisions', error);
    callbackFunction?.(error);
  });
};

type DecisionVariables = {
  [key: string]: any;
}

/**
 * Extracts the decision variable
 * @param flagKey
 * @param decisions
 * @param variableKey
 * @returns
 */
const getActiveDecisionVariable = <Type>(flagKey: string, decisions: OPAOptimizelyDecision[], variableKey: string): Type | boolean => {
  const decision = decisions.find((decision: OPAOptimizelyDecision) => decision.flagKey === flagKey && decision.enabled);

  if (!decision || !decision.variables) {
    return false;
  }

  const variables: DecisionVariables = decision.variables;

  return variables[variableKey] || null;
};

/**
 * Gets the decision variables for a given flag key from an array of decisions.
 * @param flagKey
 * @param decisions
 * @returns {null | DecisionVariables}
 */
const getActiveDecisionVariables = (flagKey: string, decisions: OPAOptimizelyDecision[]): null | DecisionVariables => {
  const decision = decisions.find((decision: OPAOptimizelyDecision) => decision.flagKey === flagKey && decision.enabled);

  if (!decision || !decision.variables) {
    return null;
  }

  const variables: DecisionVariables = decision.variables;

  return variables || null;
};

/**
 * Gets the decision variations for a given flag key from an array of decisions.
 * @param flagKey
 * @param decisions
 * @returns {string}
 */
const getActiveDecisionVariations = (flagKey: string, decisions: OPAOptimizelyDecision[]): string => {
  const decision = decisions.find((decision: OPAOptimizelyDecision) => decision.flagKey === flagKey && decision.enabled);

  if (!decision || !decision.variationKey) {
    return '';
  }

  return decision.variationKey;
};

/**
 * Returns all the flags with their variations from the cache
 */
const getAllFlagsWithVariationsFromCache = () => {
  const optimizelyCache = getSplitTestCache();

  if (!optimizelyCache?.store) {
    return {};
  }

  const cachedFlags: {[key: string]: string} = {};

  optimizelyCache.store.forEach((decisionCache: OptimizelyDecisionCache) => {
    if (decisionCache.decision?.flagKey && decisionCache.decision?.variationKey) {
      cachedFlags[`test_${decisionCache.decision?.flagKey}`] = decisionCache.decision?.variationKey;
    }
  });

  return cachedFlags;
};

export {
  getActiveDecisionVariable,
  getActiveDecisionVariables,
  getActiveDecisionVariations,
  getAllFlagsWithVariationsFromCache,
  getCachedOptimizelyDecision,
  getSplitTestConfigs,
  getSplitTestConfigsAsync,
  setOptimizelyCache
};
