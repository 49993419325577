import { getUserId } from "App/services/idpTokenService";
import { VEROnfidoToken, VERPutRelevantIdInfoRequest, VERUserVerificationStatus } from "Shared/models/swagger/ver";
import { APIResponse, MS_API } from "Shared/services/apiInterface";

enum UserVerificationURIs {
  BaseApi         = '/v1/users',
  StatusApi       = '/status',
  OnfidoTokenApi  = '/onfido/token',
  OnfidoChecksApi = '/onfido/checks',
  RelevantIdApi   = '/rvid'
}

/**
 *
 * @returns {Promise<AxiosResponse>}
 */
const getUserVerificationStatus = (): Promise<APIResponse<VERUserVerificationStatus>> => {
  const userId = getUserId();

  return MS_API({
    method : 'GET',
    baseURL: process.env.REACT_APP_VER_BASE_URL,
    url    : `${UserVerificationURIs.BaseApi}/${userId}${UserVerificationURIs.StatusApi}`
  });
};

/**
 *
 * @returns {Promise<AxiosResponse>}
 */
const getUserVerificationOnfidoToken = (): Promise<APIResponse<VEROnfidoToken>> => {
  const userId = getUserId();

  return MS_API({
    method : 'GET',
    baseURL: process.env.REACT_APP_VER_BASE_URL,
    url    : `${UserVerificationURIs.BaseApi}/${userId}${UserVerificationURIs.OnfidoTokenApi}`
  });
};

/**
 *
 * @returns {Promise<AxiosResponse>}
 */
const postUserVerificationOnfidoChecks = (): Promise<APIResponse<void>> => {
  const userId = getUserId();

  return MS_API({
    method : 'POST',
    baseURL: process.env.REACT_APP_VER_BASE_URL,
    url    : `${UserVerificationURIs.BaseApi}/${userId}${UserVerificationURIs.OnfidoChecksApi}`
  });
};

/**
 * Sends the verification answers received from Relevant Id
 */
const putRelevantIdVerificationStatus = (data: VERPutRelevantIdInfoRequest): Promise<APIResponse> => {
  const userId = getUserId();

  return MS_API({
    method : 'PUT',
    baseURL: process.env.REACT_APP_VER_BASE_URL,
    url    : `${UserVerificationURIs.BaseApi}/${userId}${UserVerificationURIs.RelevantIdApi}`,
    data   : data
  });
};

export {
  getUserVerificationOnfidoToken,
  getUserVerificationStatus,
  postUserVerificationOnfidoChecks,
  putRelevantIdVerificationStatus,
  UserVerificationURIs};