import { COOKIE_IDS } from 'App/constants';
import UserApiPatchModel from 'App/models/UserApiPatchModel';
import { VERIFY_DEVICE_URL } from 'App/routes/constants';
import { logError } from 'App/services/coralogixService';
import { DecodedAccessTokenModel, decodeJwt, getAccessTokenPayload, getRefreshTokenCookie, getUserId, setAccessTokenCookie, setRefreshTokenCookie } from 'App/services/idpTokenService';
import {
  IDP_API_CONFIG,
  IDP_EXCHANGE_HASH_TOKEN,
  IDP_RESET_PASSWORD,
  IDP_SEND_CONFIRM_EMAIL,
  IDP_SEND_DEVICE_REQUEST,
  IDP_TOKEN,
  IDP_UPDATE_PASSWORD,
  IDP_USERS,
  IDP_VERIFY_DEVICE,
  USER_ID_EXPIRY_IN_DAYS
} from 'Pages/help/constants';
import { IDPAccessTokenResponseV2, IDPPostUserRequest, IDPUserResponse } from 'Shared/models/swagger/idp';
import { API, APIResponse, MS_API } from 'Shared/services/apiInterface';
import { getCookie, setCookie } from 'Shared/services/cookieService';
import { getDeviceId } from 'Shared/services/deviceId';
import { loginToMonolith } from 'Shared/services/monolith/api/monolithApiService';
import { getUserCacheData, setUserCacheData,UserSessionCacheKeys } from 'Shared/services/sessionCacheService';
import { SwrKeys } from 'Shared/services/swr/swrKeys';
import { IDPUserResponseInternal } from 'Shared/services/userActivation/api/consts';
import useSWR, { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';

const IDP_SIGN_UP_APP       = 'WEB';
const IDP_CLIENT_ID_DESKTOP = 'DESKTOP';
const IDP_GRANT_TYPE_ENUMS  = {
  PASSWORD: 'password',
  REFRESH : 'refresh_token'
};
const IDP_GA4_CLIENT_ID = getCookie(COOKIE_IDS.GA4_CLIENT_ID) ?? undefined;

/**
 * Retrieve access token using the refresh token
 */
const getAccessTokenUsingRefresh = (refreshToken: string): Promise<APIResponse<IDPAccessTokenResponseV2>> => {
  const data     = {
    grant_type   : IDP_GRANT_TYPE_ENUMS.REFRESH,
    client_id    : IDP_CLIENT_ID_DESKTOP,
    refresh_token: refreshToken,
    clientId     : IDP_CLIENT_ID_DESKTOP,
    deviceId     : getDeviceId()
  };

  return API({
    method : 'POST',
    baseURL: process.env.REACT_APP_IDP_BASE_URL,
    url    : `${IDP_API_CONFIG}${IDP_TOKEN}`,
    headers: {
      'X-ga4ClientId': IDP_GA4_CLIENT_ID,
      'X-IDPRT': refreshToken
    },
    data   : data
  });
};

/**
 * Standard signup endpoint
 * @param email
 * @param password
 */
const getAuthTokensUsingCredentials = async (userEmail: string, userPassword: string) => {
  const headers: Record<string, string> = {
    'X-ga4ClientId': IDP_GA4_CLIENT_ID
  };

  // TODO: uncomment when IDP bug is fixed
  // See https://jira.disqotech.com/browse/SJRET-2015
  // const oldRefreshToken = getRefreshTokenCookie();
  // if (oldRefreshToken) {
  //   headers['X-IDPRT'] = oldRefreshToken;
  // }

  const response: APIResponse<IDPAccessTokenResponseV2> = await API({
    method : 'POST',
    baseURL: process.env.REACT_APP_IDP_BASE_URL,
    url    : `${IDP_API_CONFIG}${IDP_TOKEN}`,
    headers,
    data   : {
      grant_type: IDP_GRANT_TYPE_ENUMS.PASSWORD,
      client_id : IDP_CLIENT_ID_DESKTOP,
      username  : userEmail.trim(),
      password  : userPassword,
      clientId  : IDP_CLIENT_ID_DESKTOP,
      deviceId  : getDeviceId()
    }
  });

  const data         = response.data;
  const accessToken  = data.access_token;
  const refreshToken = data.refresh_token;

  if (accessToken) {
    setAccessTokenCookie(accessToken);
  }

  if (refreshToken) {
    setRefreshTokenCookie(refreshToken);
  }

  loginToMonolith();

  return response;
};

/**
 * Creates a new user when signing up
 * @param userEmail - input email when creating a new user
 * @param password - password provided by the user when signing up
 * @param marketingOptIn - Whether the user has consented to being emailed
 */
const createUser = async (userEmail: string, password: string, marketingOptIn: boolean): Promise<APIResponse> => {
  const data: IDPPostUserRequest = {
    password,
    email              : userEmail.trim(),
    marketingOptin     : marketingOptIn,
    signupApp          : IDP_SIGN_UP_APP,
    clientId           : IDP_CLIENT_ID_DESKTOP,
    // TODO: Don't cast as any once IDP's swagger documents this field
    ['deviceId' as any]: getDeviceId()
  };

  const ga4ClientId  = getCookie(COOKIE_IDS.GA4_CLIENT_ID);

  if (!!ga4ClientId) {
    data.ga4ClientId = ga4ClientId;
  }

  const response: APIResponse<IDPUserResponse> = await API({
    method : 'POST',
    baseURL: process.env.REACT_APP_IDP_BASE_URL,
    url    : `${IDP_USERS}`,
    headers: {
      Authorization  : `Basic ${process.env.REACT_APP_CREATE_USER_TOKEN}`,
      'X-ga4ClientId': IDP_GA4_CLIENT_ID
    },
    data: data
  });

  if (response.data.id) {
    setCookie(COOKIE_IDS.USER_ID, response.data.id, USER_ID_EXPIRY_IN_DAYS, true);
  }

  return response;
};

/**
 * Used to update the user's info
 * @param userInfo
 * @param userId
 */
const patchUserInfo = (userInfo: UserApiPatchModel): Promise<APIResponse<IDPUserResponse>> => {
  const userId = getUserId();

  return MS_API({
    method : 'PATCH',
    baseURL: process.env.REACT_APP_IDP_BASE_URL,
    url    : `${IDP_USERS}/${userId}`,
    headers: {
      'X-ga4ClientId': IDP_GA4_CLIENT_ID
    },
    data   : userInfo
  });
};

let userInfoPromise:Promise<APIResponse<IDPUserResponse>> | null = null;

/**
 * Used to get the user's info
 * @param queryParams
 * @param refetch
 */
const getUserInfo = (queryParams?: Record<string, string | number | boolean>, refetch= false): Promise<APIResponse<IDPUserResponse>> => {
  if (userInfoPromise && !refetch) {
    return userInfoPromise;
  }

  const userId   = getUserId();
  let requestURL = `${IDP_USERS}/${userId}`;

  // Append query parameters to URL if provided
  if (queryParams) {
    const queryString = Object.keys(queryParams)
      .map(key => `${key}=${queryParams[key]}`)
      .join('&');
    requestURL += `?${queryString}`;
  }

  userInfoPromise = MS_API({
    method : 'GET',
    baseURL: process.env.REACT_APP_IDP_BASE_URL,
    url    : requestURL,
    headers: {
      'X-ga4ClientId': IDP_GA4_CLIENT_ID
    },
  });

  userInfoPromise.then((response: APIResponse<IDPUserResponse>) => {
    const userData = convertToDateData(response.data);
    setUserCacheData(UserSessionCacheKeys.USER_INFO_WITH_DATES, response.data);

    // SJGROW-602 - SPA | US - Redirect v2 users to Monolith
    // TODO: Remove me after we stop doing the v2 redirect.
    const isNotOnDeviceVerificationPage = window.location.pathname  !== VERIFY_DEVICE_URL;
    const userIsV2User                  = userData.currentSjVersion !== process.env.SURVEYJUNKIE_SPA_VERSION;

    if (process.env.V3_USER_ACCESS_ONLY && process.env.SURVEYJUNKIE_MONOLITH_MEMBER && userIsV2User && isNotOnDeviceVerificationPage) {
      window.location.href = process.env.SURVEYJUNKIE_MONOLITH_MEMBER;
    }

    userInfoPromise = null;
  }).catch(error => {
    userInfoPromise = null;
    logError('userInfoPromise', error);
  });

  return userInfoPromise;
};

/**
 * Triggers reset password email event for user
 * @param userEmail
 */
const sendResetPassword = (userEmail: string): Promise<APIResponse> => {
  const data = {
    email: userEmail.trim(),
  };

  return API({
    method : 'POST',
    baseURL: process.env.REACT_APP_IDP_BASE_URL,
    url    : `${IDP_USERS}${IDP_RESET_PASSWORD}`,
    headers: {
      Authorization : `Basic ${process.env.REACT_APP_CREATE_USER_TOKEN}`,
      'X-ga4ClientId': IDP_GA4_CLIENT_ID
    },
    data   : data
  });
};

/**
 * Updates user password
 * @param token - Token grabbed from entry URL
 * @param password - User entered password
 */
const updateUserPassword = (token: string, password: string): Promise<APIResponse> => {
  const data = {
    password: password,
    clientId: IDP_CLIENT_ID_DESKTOP,
    deviceId: getDeviceId(),
  };

  return API({
    method : 'POST',
    baseURL: process.env.REACT_APP_IDP_BASE_URL,
    url    : `${IDP_USERS}${IDP_UPDATE_PASSWORD}`,
    headers: {
      Authorization  : `Bearer ${token}`,
      'X-ga4ClientId': IDP_GA4_CLIENT_ID
    },
    data   : data
  });
};

/**
 * Logs the user in using an auto login key, user id, and generation time
 * @param hash - The auto login key / hash
 * @param uid  - The user id
 * @param iat  - The time the hash was generated, in seconds since epoch time
 */
const loginUsingAutoLoginKey = async (hash: string, uid: number, iat: string): Promise<APIResponse<IDPAccessTokenResponseV2>> => {
  const data = {
    hash,
    uid,
    iat,
    client_id: IDP_CLIENT_ID_DESKTOP,
    clientId : IDP_CLIENT_ID_DESKTOP,
    deviceId : getDeviceId()
  };

  const headers: Record<string, any> = {
    'X-ga4ClientId': IDP_GA4_CLIENT_ID
  };

  const existingRefreshToken = getRefreshTokenCookie();
  if (existingRefreshToken) {
    headers['X-IDPRT'] = existingRefreshToken;
  }

  const response: APIResponse<IDPAccessTokenResponseV2> = await API({
    method : 'POST',
    baseURL: process.env.REACT_APP_IDP_BASE_URL,
    url    : `${IDP_USERS}${IDP_EXCHANGE_HASH_TOKEN}`,
    headers,
    data   : data
  });

  const {
    access_token,
    refresh_token
  } = response.data ?? {};

  if (access_token) {
    const existingPayload = getAccessTokenPayload(true);
    const newPayload: DecodedAccessTokenModel = decodeJwt(access_token);
    if (existingPayload?.exp ?? 0 < newPayload.exp) {
      setAccessTokenCookie(access_token);

      if (refresh_token) {
        setRefreshTokenCookie(refresh_token);
      }

      loginToMonolith();
    }
  }

  return response;
};

/**
 * Converts string date fields to Date objects
 * @param userData
 * @returns
 */
const convertToDateData = (userData: IDPUserResponse): IDPUserResponseInternal => {
    const handledDates = {
      ...userData,
      lastLogin  : userData.lastLogin ? new Date(userData.lastLogin) : null,
      lastSeenAt : userData.lastSeenAt ? new Date(userData.lastSeenAt) : null,
      createdAt  : userData.createdAt ? new Date(userData.createdAt) : new Date(),
      updatedAt  : userData.updatedAt ? new Date(userData.updatedAt) : null,
      dateOfBirth: userData.dateOfBirth ? new Date(userData.dateOfBirth) : null,
    };

    return handledDates;
};

/**
 * Used to get the user's info
 * @param userId
 */
const getUserInfoWithDates = async (optional = false): Promise<IDPUserResponseInternal | null> => {
  if (optional && !getUserId(true)) {
    return Promise.resolve(null);
  }

  try {
    const response = await getUserInfo();
    const userData = convertToDateData(response.data);
    return userData;
  } catch (err) {
    logError('getUserInfoWithDates', err);
    throw null;
  }
};
//
/**
 * Used to get the user's info with invite ID
 */
const getUserInfoWithInviteId = async (): Promise<IDPUserResponse & { inviteId?: string } | null> => {
  if (!getUserId(true)) {
    return Promise.resolve(null);
  }

  try {
    const response = await getUserInfo({ include: 'inviteId' }, true);
    setUserCacheData(UserSessionCacheKeys.USER_INFO_WITH_INVITE_ID, response.data);
    return response.data;
  } catch (err) {
    logError('getUserInfoWithInviteId', err);
    throw null;
  }
};

interface PostSendDeviceRequestOpts {
  authCode: string;
}

/**
 * Sends a request to IDP to send a device request email
 * @param opts An object containing the auth code for sending the email
 * @returns An Axios api response containing the data returned by IDP
 */
const postSendDeviceRequest = async ({ authCode }: PostSendDeviceRequestOpts) => {
  const headers = {
    'X-ga4ClientId': IDP_GA4_CLIENT_ID,
    'Authorization': `Bearer ${authCode}`
  };

  return API({
    method : 'POST',
    baseURL: process.env.REACT_APP_IDP_BASE_URL,
    url    : `${IDP_USERS}${IDP_SEND_DEVICE_REQUEST}`,
    headers,
    data: {
      deviceId: getDeviceId(),
      clientId: IDP_CLIENT_ID_DESKTOP
    }
  });
};

interface PostVerifyDeviceRequestOpts {
  authCode: string;
}

/**
 * Sends a request to IDP to verify the current device
 * @param opts An object containing the auth code for verifying the device
 * @returns An Axios api response containing the data returned by IDP
 */
const postVerifyDeviceRequest = async ({ authCode }: PostVerifyDeviceRequestOpts) => {
  const userId = getUserId();
  const headers = {
    'X-ga4ClientId': IDP_GA4_CLIENT_ID,
    'Authorization': `Bearer ${authCode}`
  };

  return API({
    method : 'POST',
    baseURL: process.env.REACT_APP_IDP_BASE_URL,
    url    : `${IDP_USERS}/${userId}${IDP_VERIFY_DEVICE}`,
    headers,
    data: {
      deviceId : getDeviceId(),
      clientId: IDP_CLIENT_ID_DESKTOP
    }
  });
};

/**
 * Sends a confirmation email to the user
 * @returns An Axios api response containing the data returned by IDP
 */
const resendEmailConfirmationRequest = (): Promise<APIResponse> => {
  const userId = getUserId();

  return MS_API({
    method : 'POST',
    baseURL: process.env.REACT_APP_IDP_BASE_URL,
    url    : `${IDP_USERS}/${userId}${IDP_SEND_CONFIRM_EMAIL}`,
  });
};

/**
 * SWR hook for getting user info
 * @param optional
 * @returns
 */
const useGetUserInfo = (optional = false) => {
  const initialData = getUserCacheData(UserSessionCacheKeys.USER_INFO_WITH_DATES);
  const isValidData = initialData && initialData.createdAt != null;
  const config      = isValidData ? { fallbackData: convertToDateData(initialData) } : undefined;
  const swr         = useSWR(SwrKeys.UserInfo, () => getUserInfoWithDates(optional), config);
  let   isLoading   = swr.isLoading;

  if (isValidData) {
    isLoading = false;
  }

  return {
    ...swr,
    isLoading
  };
};

/**
 * SWR Hook for getting user info with referral invite ID
 * @returns
 */
const useGetUserInfoWithInviteId = () => {
  const initialData = getUserCacheData(UserSessionCacheKeys.USER_INFO_WITH_INVITE_ID);
  const swr         = useSWR(SwrKeys.UserInfoWithInviteId, () => getUserInfoWithInviteId(), {
    revalidateIfStale    : false,
    revalidateOnFocus    : false,
    revalidateOnReconnect: false,
    fallbackData         : initialData
  });

  let isLoading = swr.isLoading;

  if (typeof initialData !== 'undefined') {
    isLoading = false;
  }

  return {
    ...swr,
    isLoading
  };
};


const usePatchUserInfo = () => {
  return useSWRMutation<APIResponse<IDPUserResponse>, any, string, UserApiPatchModel>(SwrKeys.UserInfo, (_, { arg }) => patchUserInfo(arg));
};

const usePostSendDeviceRequest = () => {
  return useSWRMutation<APIResponse, any, string, PostSendDeviceRequestOpts>(
    SwrKeys.SendDeviceRequest,
    (_, { arg }) => postSendDeviceRequest(arg)
  );
};

interface AuthCreds {
  userName: string;
  password: string;
}

const useAuthTokensUsingCredentials = () => {
  const { mutate } = useSWRConfig();
  return useSWRMutation<APIResponse<IDPAccessTokenResponseV2>, any, string, AuthCreds>(
    SwrKeys.AuthTokenUsingCredentials,
    async (_, { arg: { userName, password }}) => {
      const result = await getAuthTokensUsingCredentials(userName, password);
      mutate(() => true);

      return result;
    }
  );
};

interface LoginUsingAutoLoginKeyOpts {
  hash: string;
  uid : number;
  iat : string
}

const useLoginUsingAutoLoginKey = () => {
  return useSWRMutation<APIResponse, any, string, LoginUsingAutoLoginKeyOpts>(
    SwrKeys.LoginUsingAutoLoginKey,
    (_, { arg: { hash, uid, iat } }) => loginUsingAutoLoginKey(hash, uid, iat)
  );
};

const useSendResetPassword = () => {
  return useSWRMutation<APIResponse, any, string, string>(
    SwrKeys.SendResetPassword,
    (_, { arg: password }) => sendResetPassword(password)
  );
};

const usePostVerifyDeviceRequest = () => {
  return useSWRMutation<APIResponse, any, string, PostVerifyDeviceRequestOpts>(
    SwrKeys.VerifyDevice,
    (_, { arg }) => postVerifyDeviceRequest(arg)
  );
};

export {
  createUser,
  getAccessTokenUsingRefresh,
  getAuthTokensUsingCredentials,
  getUserInfo,
  loginUsingAutoLoginKey,
  patchUserInfo,
  postSendDeviceRequest,
  postVerifyDeviceRequest,
  resendEmailConfirmationRequest,
  sendResetPassword,
  updateUserPassword,
  useAuthTokensUsingCredentials,
  useGetUserInfo,
  useGetUserInfoWithInviteId,
  useLoginUsingAutoLoginKey,
  usePatchUserInfo,
  usePostSendDeviceRequest,
  usePostVerifyDeviceRequest,
  useSendResetPassword
};
