import { logError } from 'App/services/coralogixService';

enum MobileAppEvent {
  initialized                     = 'initialized',
  error                           = 'error',
  close                           = 'close',
  navigate                        = 'navigate',
  startVirtualIncentiveRedemption = 'start_virtual_incentive_redemption',
  endVirtualIncentiveRedemption   = 'end_virtual_incentive_redemption',
  startBankTransferRedemption     = 'start_bank_redemption',
  endBankTransferRedemption       = 'end_bank_redemption',
  endRvId                         = 'end_rvid',
}

enum MobileAppPages {
  contactUs                  = 'contact_us',
  memberHome                 = 'member_home',
  rewards                    = 'rewards',
  virtualIncentiveRedemption = 'virtual_incentive_redemption',
  bankTransferRedemption     = 'bank_transfer_redemption'
}

enum MobileAppErrors {
  invalidAuth      = 'invalid_auth',
  unsupportedEvent = 'unsupported_event',
  badEventRequest  = 'bad_event_request'
}

enum WebVirtualIncentiveCloseStatus {
  success = 'success',
  fail    = 'fail',
  cancel  = 'cancel'
}


enum WebBankTransferCloseStatus {
  success = 'success',
  fail    = 'fail',
  cancel  = 'cancel'
}

type WebInitializedEvent = {
  event: MobileAppEvent.initialized
}

type WebErrorEvent = {
  event     : MobileAppEvent.error,
  error_code: string
}

type WebCloseEvent = {
  event: MobileAppEvent.close
}

type WebNavigateEvent = {
  event    : MobileAppEvent.navigate,
  page     : MobileAppPages,
  referrer?: MobileAppPages
}

type WebStartVirtualIncentiveRedemptionEvent = {
  event: MobileAppEvent.startVirtualIncentiveRedemption,
  sku  : string
}

type WebEndVirtualIncentiveRedemptionEvent = {
  event            : MobileAppEvent.endVirtualIncentiveRedemption,
  status           : WebVirtualIncentiveCloseStatus,
  amount_in_points?: number
}

type WebStartBankTransferRedemptionEvent = {
  event: MobileAppEvent.startBankTransferRedemption,
}

type WebEndBankTransferRedemptionEvent = {
  event            : MobileAppEvent.endBankTransferRedemption,
  status           : WebBankTransferCloseStatus
  amount_in_points?: number
}

type WebCallback = {
  event   : MobileAppEvent,
  callback: (event: WebEvent) => void
}

type WebEndRvIdEvent = {
  event: MobileAppEvent.endRvId
}

type WebEvent = WebInitializedEvent | WebErrorEvent | WebCloseEvent | WebNavigateEvent | WebStartVirtualIncentiveRedemptionEvent | WebEndVirtualIncentiveRedemptionEvent | WebStartBankTransferRedemptionEvent | WebEndBankTransferRedemptionEvent | WebEndRvIdEvent;

//
// Uncomment this if you want to emulate an Android app
//
// window.sjAppInterface = {
//   postMessage: console.log.bind(console),
// };

// List of callbacks
let webCallbacks: WebCallback[] = [];

// Detect for the presence of the mobile app
const isInIOSApp     = () => !!window.webkit?.messageHandlers?.sjAppInterface?.postMessage;
const isInAndroidApp = () => !!window.sjAppInterface?.postMessage;

/**
 * Send a message to the mobile app
 * @param event
 * @returns void
 */
const sendAppMessage = (event: WebEvent): void => {
  try {
    const eventString = JSON.stringify(event);

    // Handle SJ iOS App
    if (isInIOSApp()) {
      window.webkit?.messageHandlers?.sjAppInterface?.postMessage(eventString);
      return;
    }

    // Handle SJ Android App
    if (isInAndroidApp()) {
      window.sjAppInterface?.postMessage(eventString);
      return;
    }
  } catch (error) {
    logError('sendAppMessage', error);
    return;
  }
};

/**
 * Callback handler for mobile app events
 * @param {event, callback}: WebCallback
 */
const listenForAppMessage = ({event, callback}: WebCallback): void => {
  webCallbacks = webCallbacks.filter((webCallback: WebCallback) => {
    return webCallback.event !== event;
  });

  webCallbacks.push({
    event,
    callback
  });
};

/**
 * Checks whether the app is in iOS or Android apps
 * @returns boolean
 */
const isInMobileApp = (): boolean => {
  return isInIOSApp() || isInAndroidApp();
};

/**
 * Handle receiveing of events from SJ Mobile App
 * Events are received in the following format:
 * {
 *   event: event name,
 *   ...
 * }
 *
 * @param receivedAppMessage
 * @returns void
 */
window.receiveAppMessage = (receivedAppMessage: string) => {
  let event: WebEvent;

  try {
    event = JSON.parse(receivedAppMessage);
  } catch (error) {
    sendAppMessage({
      event     : MobileAppEvent.error,
      error_code: MobileAppErrors.badEventRequest
    });
    logError('receiveAppMessage', error);
    return;
  }

  if (!event?.event) {
    sendAppMessage({
      event     : MobileAppEvent.error,
      error_code: MobileAppErrors.badEventRequest
    });
    return;
  }

  for (const mobileAppEvent of Object.values(MobileAppEvent)) {
    if (event?.event === mobileAppEvent) {
      webCallbacks.forEach((webCallback: WebCallback) => {
        //trigger callback only for specific app message received
        if (webCallback.event === event?.event) {
          webCallback.callback(event);
        }
      });
      return;
    }
  }

  sendAppMessage({
    event     : MobileAppEvent.error,
    error_code: MobileAppErrors.unsupportedEvent
  });
};

export {
  isInAndroidApp,
  isInIOSApp,
  isInMobileApp,
  listenForAppMessage,
  MobileAppErrors,
  MobileAppEvent,
  MobileAppPages,
  sendAppMessage,
  WebBankTransferCloseStatus,
  WebCloseEvent,
  WebEndBankTransferRedemptionEvent,
  WebEndVirtualIncentiveRedemptionEvent,
  WebErrorEvent,
  WebEvent,
  WebInitializedEvent,
  WebNavigateEvent,
  WebStartBankTransferRedemptionEvent,
  WebStartVirtualIncentiveRedemptionEvent,
  WebVirtualIncentiveCloseStatus
};
