export enum RouteQueryParam {
  // Used to indicate that the user has completed the survey medley
  SURVEY_MEDLEY_COMPLETE       = 'smc',
  SURVEY_MEDLEY_QUESTION_COUNT = 'qc',

  // Query Params passed to survey qualification page, mainly used for smartlook videos
  SURVEY_ID_QUERY_PARAM    = 'sId',
  PROVIDER_ID_QUERY_PARAM  = 'pId',
  QUESTION_IDS_QUERY_PARAM = 'qIds',
}

export const PAGE_MEMBER                = '/';
export const REWARDS_PAGE               = '/rewards';
export const MEMBER_SIGN_IN_URL         = '/public/signin';
export const SHUT_DOWN_CONTACT_US       = '/contact-us';
export const SHUT_DOWN_TERMS            = '/terms';
export const SHUT_DOWN_PRIVACY          = '/privacy';
export const MEMBER_SIGN_UP_URL         = '/public/signup';
export const MEMBER_SIGN_UP_URL_NO_AUTH = '/public/signup-no-auth'; // used for development & e2e only
export const MEMBER_WELCOME_URL         = '/welcome';
export const VERIFY_DEVICE_URL          = '/device-verification-status';
export const MEMBER_FORGOT_PASSWORD_URL = '/forgot-password';
export const PRE_MEMBER_LOGOUT_URL      = '/logout';
export const PINNING_INSTRUCTIONS       = '/pinning-instructions';
export const UNINSTALL                  = '/uninstall-confirmation';
export const UNRECOGNIZED_DEVICE_URL    = '/unrecognized-device';
export const STE_LEARN_MORE             = '/ste-learn-more';
export const SURVEY_MEDLEY_URL          = '/survey-medley';
export const AUTO_LOGIN_URL             = `/al`;
export const MEMBER_PANELS_URL          = '/panels';
export const PAGE_NOT_FOUND             = '/404';

export const SURVEY_QUALIFICATION_ROUTE = '/survey-qualification';
