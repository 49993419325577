import { AUTO_LOGIN_URL, PAGE_MEMBER } from 'App/routes/constants';
import {
  COUNTRY_CODE_US,
  PHONE_COUNTRY_CODE_US,
} from 'Shared/constants';

/**
 * Converts amount to local currency formatting with 2 decimal spaces
 */
export const getLocalCurrency = (amount: number): string => {
  if (amount) {
    return amount.toLocaleString(undefined,{ minimumFractionDigits: 2, maximumFractionDigits: 2});
  }

  if (amount === 0) {
    return '0';
  }

  return '';
};

export const  getCountryBuild = (): string => {
  return COUNTRY_CODE_US;
};

/**
 * Performs date conversion to wanted format depending on country build
 * UK: 12 April 2022
 * US: April 12 2022
 * @param date
 * @param includeYear
 * @param timeZone
 * @returns
 */
export const convertDate = (
  date: string | Date,
  includeYear = false,
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
): string => {
    const locale = 'en-US';
    const d = new Date(date);

    if (includeYear) {
      const options: Intl.DateTimeFormatOptions = {
        month   : "long",
        day     : "2-digit",
        year    : "numeric",
        timeZone
      };

      return d.toLocaleString(locale, options );
    }

    const options: Intl.DateTimeFormatOptions = {
      month   : "long",
      day     : "2-digit",
      timeZone
    };

    return d.toLocaleString(locale, options );
  };

/**
 * Returns the appropriate international phone number country code
 * @returns
 */
export const getPhoneNumberRegionCode = (): string => {
  return PHONE_COUNTRY_CODE_US;
};

/**
 * Clean up phone number before saving to state
 * @param phoneNumber
 * @returns
 */
export function sanitizePhoneNumber(phoneNumber: string): string {
  return phoneNumber.trim().replace(/ /g, '');
}

/**
 * Accepts a date string and converts it to a universal date string format
 * @param dateInput
 * @returns
 */
export const convertDateInputToDate = (dateInput: string): Date => {
  const [partOne, partTwo, partThree] = dateInput.split('-');
  return new Date(parseInt(partOne), parseInt(partTwo) - 1, parseInt(partThree));
};

/**
 * Accepts a date string and returns a date object
 * @param dateInput
 * @returns string
 */
export const convertDateInputToDateISO = (dateInput: string): string => {
  return convertDateInputToDate(dateInput).toISOString();
};

/**
 * Converts milliseconds to days
 * @param milliseconds
 * @returns
 */
export const convertMillisecondsToDays = (milliseconds: number): number => {
  return milliseconds / 1000 / 60 / 60 / 24;
};

/**
 * Converts seconds to milliseconds
 * @param seconds
 * @returns
 */
export const convertSecondsToMilliseconds = (seconds: number): number => {
  return seconds * 1000;
};

/**
 * Returns the brightness of an image
 * @param image
 * @returns number between 0 and 255
 */
export const getImageBrightness = (image: HTMLImageElement): number => {
  const WHITE_RGB = 255;
  const canvas    = document.createElement('canvas');
  const isLoaded  = image.complete && image.naturalHeight !== 0;

  if (!isLoaded) {
    return WHITE_RGB;
  }

  const imageWidth  = image.width;
  const imageHeight = image.height;
  canvas.width      = imageWidth;
  canvas.height     = imageHeight;

  const ctx         = canvas.getContext('2d');

  if (!ctx) {
    return WHITE_RGB;
  }

  ctx.drawImage(image,0,0);

  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  const data      = imageData.data;
  let colorSum    = 0;
  let r, g, b, averageColor;

  for (let x = 0; x < data.length; x += 4) {
    r             = data[x];
    g             = data[x+1];
    b             = data[x+2];
    averageColor  = Math.floor((r+g+b)/3);
    colorSum     += averageColor;
  }

  const colorBrightness = Math.floor(colorSum / (imageWidth * imageHeight));

  return colorBrightness;
};

/**
 * Returns the brightness of an image
 * @param image
 * @returns boolean
 */
export const isDarkImage = (image: HTMLImageElement | null | undefined): boolean => {
  if (!image?.complete) {
    return false;
  }

  const rgbColorSpectrumRange = 255;
  const colorSpectrumPadding  = 4;
  return getImageBrightness(image) < ((rgbColorSpectrumRange/2) + colorSpectrumPadding);
};

/**
 * Checks surveyId length and truncates it to 10 characters
 * @param surveyId
 * @returns
 */
export const getValidatedSurveyId = (surveyId: string): string => {
  const SURVEY_ID_MAX_CHAR = 10;

  if (surveyId.length > SURVEY_ID_MAX_CHAR) {
    return surveyId.substring(0, SURVEY_ID_MAX_CHAR);
  }

  return surveyId;
};

/**
 * Round to x decimal places and add missing zeros if needed (e.g. 120 -> 1.20, 200 -> 2)
 */
export const roundToDecimalPlaces = (value: number, decimals: number): string => {
  const multiplier = Math.pow(10, decimals);
  let roundedValue = (Math.round(value * multiplier) / multiplier).toString();

  if (roundedValue.includes('.')) {
    const [integerPart, decimalPart] = roundedValue.split('.');
    roundedValue = integerPart + '.' + decimalPart.padEnd(decimals, '0');
  }

  return roundedValue;
};

/**
 * Extracts the path after '/al' from the auto login URL
 * @param url
 */
export const extractAutoLoginRedirectionPath = (url: string) => {
  if (!url || !url.includes(AUTO_LOGIN_URL)) {
    return PAGE_MEMBER;
  }

  return url.substring(url.indexOf(AUTO_LOGIN_URL) + AUTO_LOGIN_URL.length) || PAGE_MEMBER;
};

/**
 * Returns the number of unique providerIds in the surveysList
 * @param surveysList
 * @returns number
 */
export const uniqueProviderIdsSize = (surveysList: Array<object>, onlyProviderIds: number[] | null = null) => {
  const uniqueProviderIds = new Set();
  surveysList.forEach((item: any) => {
    if (onlyProviderIds && !onlyProviderIds.includes(item.providerId)) {
      return;
    }

    uniqueProviderIds.add(item.providerId);
  });

  return uniqueProviderIds.size;
};

/**
 * Converts UTC timestamp to local time
 * Shows time only if within the last 24 hours, otherwise shows date and time
 * @param utcTimestamp
 * @returns
 */
export const convertUTCToLocalTime = (utcTimestamp: string): string => {
  // convert to date object
  const utcDate = new Date(utcTimestamp);
  const now     = new Date();

  // get the time difference in milliseconds
  const timeDifference      = now.getTime() - utcDate.getTime();
  const twentyFourHoursInMs = 24 * 60 * 60 * 1000;

  // format options
  const timeOptions: Intl.DateTimeFormatOptions = {
    hour  : 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  const dateOptions: Intl.DateTimeFormatOptions = {
    year  : 'numeric',
    month : 'short',
    day   : 'numeric',
    hour  : 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  // if more than 24 hours ago, show date and time, else show only time
  if (timeDifference > twentyFourHoursInMs) {
    return utcDate.toLocaleString('en-US', dateOptions);
  } else {
    return utcDate.toLocaleTimeString('en-US', timeOptions);
  }
};
